
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonIcon,
  IonContent,
  IonButton,
  loadingController,
  toastController,
} from "@ionic/vue";
import { ref } from "vue";
import {
  alertCircleOutline,
  addCircleOutline,
  schoolOutline,
  logoNoSmoking,
  languageOutline,
  triangleOutline,
  briefcaseOutline,
} from "ionicons/icons";
import router from "@/router";

import CriteriaEditForm from "@/components/criteria/CriteriaEditForm.vue";
import CriteriaEditFormSkeleton from "@/components/criteria/CriteriaEditFormSkeleton.vue";

import {
  myCriteria,
  errorOccured,
  loadMyCriteria,
  saveMyCriteria,
  loadingMyCriteria,
  validateCriteriaAreNok,
  validationOn,
} from "@/components/criteria";

import { user } from "@/auth";
import { useI18n } from "vue-i18n";

export default {
  name: "RegCriteria",
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonIcon,
    IonContent,
    CriteriaEditForm,
    CriteriaEditFormSkeleton,
    IonButton,
  },

  setup() {
    const { t } = useI18n({ useScope: "global" });
    const overlayShow = ref<boolean>(true);
    const loading = ref(loadingMyCriteria);

    loadMyCriteria();
    let savingLoader: any;
    const presentLoader = async () => {
      savingLoader = await loadingController.create({
        message: t("criteria.saving"),
      });

      await savingLoader.present();
    };

    const openErrorToast = async () => {
      const toast = await toastController.create({
        message: t("criteria.savingFailed"),
        color: "danger",
        position: "bottom",
        buttons: [
          {
            text: t("buttons.cancel"),
            role: "cancel",
          },
        ],
      });
      return toast.present();
    };

    const saveAndContinue = async () => {
      if (errorOccured.value) {
        return;
      }

      if (validateCriteriaAreNok()) {
        validationOn.value = true;
        document.getElementById("sexSectionCriteria")?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        return;
      }

      await presentLoader();

      const { result, error } = await saveMyCriteria();
      if (result == "success") {
        router.push("/registration/profile");
      } else {
        openErrorToast();
      }

      await savingLoader.dismiss();
    };

    return {
      overlayShow,
      user,
      // moveToProfileDisabled,
      loading,
      myCriteria,
      errorOccured,
      saveAndContinue,

      // ICONS
      alertCircleOutline,
      addCircleOutline,
      schoolOutline,
      logoNoSmoking,
      languageOutline,
      triangleOutline,
      briefcaseOutline,
    };
  },
};
